/*------------------------------------*\
    #BREAKPOINTS-SETTINGS
\*------------------------------------*/

$breakpoints:(
        xs: 20em, // 320 pixels
        s:  30em, // 480 pixel
        m:  48em, // 768 pixels
        l:  60em, // 960 pixels
        xl: 80em); // 1280 pixels

/**
 * Breakpoint management.
 * @author Hugo Giraudel
 * @link http://www.sitepoint.com/better-solution-managing-z-index-sass/
*/

@function get-breakpoint($name) {

    $bp: map-get($breakpoints, $name);

    @if not $bp {
        @error 'No value found for `#{$name}`. Please make sure it is defined in `$breakpoints` map.';
    }

    @return $bp;

}
