.o-share-stack {
  background: rgba(get-color('brand'), .75);
  padding: baseline(1) 0;
  text-align: center;

  .o-share-stack__back {
    padding-left: baseline(.75);
    font-size: 80%;
    position: relative;

    .a-icon {
      left: 0;
      position: absolute;
      transform: rotate(180deg);
      top: .35em;
    }

  }

  .o-share-stack__title {
    font-family: $font-heading-light;
    font-size: 80%;
    font-weight: normal;
    line-height: 1;
  }

  .o-share-stack__links {
    margin-top: baseline(.5);
    position: relative;
    top: baseline(-.5);

    .a-icon {
      line-height: 1.5rem;
    }

    li {
      margin-top: baseline(.5);
      margin-left: baseline(.5);
      margin-right: baseline(.5);
    }

    a {
      text-decoration: none;
    }

  }

  .o-hero & {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  @include respond-min('m'){
    text-align: left;

    .a-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .o-share-stack__social,
    .o-share-stack__back {
      flex: 0 0 auto;
    }

    .o-share-stack__social {
      align-self: flex-end;
      margin-left: baseline(1);
      margin-top: -.55em; // magic numbers ftw
    }

    .o-share-stack__title,
    .o-share-stack__links {
      display: inline-block;
      margin-top: 0;
      vertical-align: baseline;
    }

    .o-share-stack__title,

    .o-share-stack__links {
      top: 0;

      li {
        margin-top: 0;
      }
    }

  }

}