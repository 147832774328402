.a-triangle {
  position: relative;

  &::before {
    border-style: solid;
    content: "";
    position: absolute;
    display: block;
    width: 0;
    border-width: baseline(.5);
  }

  &--bottom {
    &::before {
      bottom: baseline(-.5);
      left: 50%;
      transform: translateX(-50%);
      border-bottom-width: 0;
      border-left-color: transparent;
      border-right-color: transparent;
    }
  }
}