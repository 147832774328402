/*------------------------------------*\
  #BUTTONS
\*------------------------------------*/

/*
* 1. reset buttons
*/

button,
.a-button {
  background-color: transparent;
  border:0;
  cursor: pointer;
  display: inline-block;
  font-size: 100%;
  font-family: $font-heading-regular;
  font-weight: $font-weight-regular;
  line-height: 1.4em;
  outline: 0;
  padding: get-spacing('inset-squish', 's');
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: auto;
  white-space: nowrap;
}

.a-button--default {
  border-color: get-color('brand');
  background-color: transparent;
  color: get-color('brand');
  border-style: solid;
  letter-spacing: .1rem;
  border-width: 1px;
  font-weight: $font-weight-regular;
  font-size: 100%;

  @include attention {
    background-color: get-color('brand');
    color: get-color('white');
  }

  .u-diapositive & {
    background-color: get-color('white');
    border-color: get-color('white');
    color: get-color('brand');

    .a-icon {
      color: get-color('brand');
    }

    @include attention {
      background-color: transparent;
      color: get-color('white');

      .a-icon {
        color: get-color('white');
      }
    }
  }

  &[disabled],
  &.a-button--disabled {
    opacity: .3;
    cursor: not-allowed;

    @include attention {
      border-color: get-color('brand');
      background-color: transparent;
      color: get-color('brand');
    }

    .u-diapositive & {
      background-color: transparent;
      border-color: get-color('white');
      color: get-color('white');

      .a-icon {
        color: get-color('white');
      }
    }
  }

  /*
  * 1. ON TABLET THE BUTTONS ARE ALWAYS SMALL
  */

  @include respond-min('m') {
    padding: get-spacing('inset-squish', 'm');
    font-size: 80%;
  }
}



/* Button Square (icons)
----------------------------------------------- */

.a-button--square {
  background-color: get-color('white');
  border-color: get-color('white');
  color: get-color('brand');
  opacity: .8;
  font-size: 1.125em;
  padding: 0;
  width: baseline(1.5);
  height: baseline(1.5);
  line-height: baseline(1.5);

  @include attention {
    border-color: get-color('brand');
    background-color: get-color('white');
    color: get-color('brand');
    opacity: 1;
  }
}

.a-button--square-large {
  background-color: get-color('brand');
  border-color: get-color('brand');
  color: get-color('white');
  opacity: .5;
  padding: 0;
  width: baseline(3);
  height: baseline(3);
  line-height: baseline(3);
  font-size: 250%;

  @include attention {
    border-color: get-color('brand');
    background-color: get-color('brand');
    color: get-color('white');
    opacity: 1;
  }
}

/* Button Ghost
----------------------------------------------- */

.a-button--ghost {
  border-color: get-color('white');
  background-color: transparent;
  color: get-color('white');
  padding: get-spacing('inset-squish', 's');
  font-size: 100%;
  border-style: solid;
  letter-spacing: .1rem;
  border-width: 1px;

  @include attention {
    background-color: get-color('white');
    color: get-color('brand');

    .a-icon {
      color: get-color('brand');
    }
  }

  @include respond-min('m') {
    padding: get-spacing('inset-squish', 'm');
    font-size: 80%;
  }
}

/* Button CTA
----------------------------------------------- */

.a-button--cta {
  border-color: get-color('brand');
  background-color: get-color('brand');
  color: get-color('white');

  @include attention {
    background-color: get-color('white');
    color: get-color('brand');

    .a-icon {
      color: get-color('brand');
    }
  }
}

/* Button Link
----------------------------------------------- */

.a-button--link {
  color: get-color('brand');
  display: inline-block;

}

/* Button Sizes
----------------------------------------------- */

.a-button--small {
  padding: get-spacing('inset-squish', 's');
  font-size: 100%;

  .a-icon {
    font-size: 100%;
  }

  @include respond-min('m') {
    font-size: 70%;
  }
}


/* Button Diapositive
----------------------------------------------- */

.a-button--diapositive {

  @include attention {
    border-color: get-color('white');
  }

}

.a-buttons {
  margin-top: baseline(1);

  &--center {
    text-align: center;
  }
}


/* Button Loading
----------------------------------------------- */
.a-button--loading {
  background: transparent url("../img/loading.gif") no-repeat center center;
  border-color: transparent;
  font-size: 0;
  height: 50px;

  @include attention {
    border-color: transparent !important;
  }
}

.a-button--block {
  display: block;
}