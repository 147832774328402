.u-show {
  display: inline-block!important;

  @include respond-min(xs) {
    &-xs {
      display: inline-block!important;
    }
  }

  @include respond-min(s) {
    &-s {
      display: inline-block!important;
    }
  }

  @include respond-min(m) {
    &-m {
      display: inline-block!important;
    }
  }

  @include respond-min(l) {
    &-l {
      display: inline-block!important;
    }
  }

  @include respond-min(xl) {
    &-xl {
      display: inline-block!important;
    }
  }
}

.u-hide {
  display: none;

  @include respond-min(xs) {
    &-xs {
      display: none!important;
    }
  }

  @include respond-min(s) {
    &-s {
      display: none!important;
    }
  }

  @include respond-min(m) {
    &-m {
      display: none!important;
    }
  }

  @include respond-min(l) {
     &-l {
       display: none!important;
     }
   }

  @include respond-min(xl) {
    &-xl {
      display: none!important;
    }
  }
}

