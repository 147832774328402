$spacing-stack: (
  'xs': baseline(.25),
  's': baseline(.5),
  'm': baseline(1),
  'l': baseline(2),
  'xl': baseline(3)
);

$spacing-inset: (
  'xs': baseline(.25),
  's': baseline(.5),
  'm': baseline(1),
  'l': baseline(2),
  'xl': baseline(3)
);

$spacing-inset-squish: (
  's': baseline(.25) baseline(.75),
  'm': baseline(.5) baseline(1.75)
);

$spacing-inline: (
  'xs': baseline(.25),
  's': baseline(.5),
  'm': baseline(1),
  'l': baseline(2),
  'xl': baseline(3)
);

@function get-spacing($type: 'inset', $amount: 'xs') {

  $map: null;

  @if ($type == 'inset') {
    $map: $spacing-inset;
  } @else if($type == 'inset-squish') {
    $map: $spacing-inset-squish;
  } @else if($type == 'stack') {
    $map: $spacing-stack;
  } @else if($type == 'inline') {
    $map: $spacing-inline;
  }

  @if $map == null {
    @warn 'No map of type #{$type} found.';
  }

  @if map-has-key($map, $amount) {
    @return map-get($map, $amount);
  } @else {
    @warn 'No value #{amount} found.';
  }

}

@function stack($amount) {
  @return get-spacing('stack', $amount);
}

@function inset($amount) {
  @return get-spacing('inset', $amount);
}

@function inset-squish($amount) {
  @return get-spacing('inset-squish', $amount);
}

@function inline($amount) {
  @return get-spacing('inline', $amount);
}