/*------------------------------------*\
    #BOX-SIZING
\*------------------------------------*/

/**
 * Box-sizing best practice.
 * @link https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */

html {
  box-sizing: border-box; /* 1 */
}

* {

  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }

}