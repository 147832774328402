// -----------------------------------------------------------------------------
// Layout
// -----------------------------------------------------------------------------

.m-layout {
    @include list-bare;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;

    + .m-layout {
        margin-top: 0;
    }

    .m-layout__item {
        flex: 0 0 auto;
        margin-top: baseline(1);
    }
}

.m-layout--rev {
    flex-direction: row-reverse;
}

.m-layout--gutter {
    margin-left: -$layout-gutter;
    margin-right: -$layout-gutter;

    .m-layout__item {
        padding-left: $layout-gutter;
        padding-right: $layout-gutter;
    }

}

.m-layout--list {

    .m-layout__item {
        display: flex;
        flex-direction: column;

        > * {
            flex: 1 1 auto;
            height: 100%;
        }

    }

}