/*------------------------------------*\
    #Z-INDEX SETTINGS
\*------------------------------------*/

$z-layers: (
    "alpha":    (
        "alpha-1":  500,
        "alpha-2":  100,
        "alpha-3":  50,
        "alpha-4":  10
    ),
    "beta":     8000,
    "gamma":    1,
    "delta":    -1,
    "epsilon":  -9000
);