// ----
// Sass (v3.3.7)
// Compass (v1.0.0.alpha.18)
// ----

// Helper mixin for offset positioning
// About: http://hugogiraudel.com/2013/08/05/offsets-sass-mixin/
// ---
// Here is an improved version adding some extra features
// ---
// @param [string] $position: position
// @param [list] $args (()): offsets list
// ---
// Explanations
// 1. Output position
// 2. If `$args` is not empty
// 3. Loop other the 4 popular offsets (`$offsets`)
// 4. Check if the current one exists in `$args`
// 5. If it is found in `$args`
// 6. And if it is at last position
// 7. Set it to 0
// 8. Else if it is found at any other index
// 9. Store the next value from `$args`
// 10. If it is a valid length
// 11. Set the offset to this value
// 12. Else if it is another offset
// 13. Set the offset to 0
// 14. Else
// 15. Warn the user
// ---

@mixin position($position, $args: ()) {
    $offsets: top right bottom left;
    position: $position;                                               // 1

    @each $offset in $offsets {                                        // 3
        $index: index($args, $offset);                                   // 4

        @if $index {                                                     // 5
            @if $index == length($args) {                                  // 6
                #{$offset}: 0;                                               // 7
            } @else {                                                        // 8
                $next: nth($args, $index + 1);                               // 9
                @if is-valid-length($next) {                                 // 10
                    #{$offset}: $next;                                         // 11
                } @else if index($offsets, $next) {                            // 12
                    #{$offset}: 0;                                             // 13
                } @else {                                                      // 14
                    @warn "Invalid value `#{$next}` for offset `#{$offset}`."; // 15
                }
            }
        }
    }
}

// Function checking if $value is a valid length
// ---
// @param [literal] $value: value to test
// ---
// @return [bool]

@function is-valid-length($value) {
    @return (type-of($value) == 'number' and not unitless($value))
       or (index(auto initial inherit 0, $value) != false);
}

// Shorthands
// ---

@mixin absolute($args: ()) {
    @include position(absolute, $args);
}

@mixin fixed($args: ()) {
    @include position(fixed, $args);
}

@mixin relative($args: ()) {
    @include position(relative, $args);
}