.o-video-reveal {
  background-color: get-color('brand');
  margin-top: 0;
  overflow: hidden;
  position: relative;

  canvas,
  .o-video-reveal__video {
    margin-top: 0;
  }

  .o-video-reveal__button {
    background: rgba(get-color('black'), .25);
    color: get-color('white');
    font-size: 70%;
    position: absolute;
    bottom: 50px;
    right: 50px;
    padding: 50px 75px;
    z-index: 10;
    height: 200px;
    width: 200px;
    text-align: center;
    border-radius: 50%;
    transform: translateX(50%) translateY(50%);
    white-space: normal;

    span {
      display: block;
      margin-top: 0;
      font-family: EffraLight;
    }

    .a-icon {
      font-size: 150%;
      margin-top: baseline(-.25);
      margin-bottom: baseline(.25);
    }

    @include respond-min('m'){
      bottom: 100px;
      padding: 25px;
      right: 100px;
      width: 100px;
      height: 100px;
    }

  }

  .o-video-reveal__content {
    opacity: 1;
    overflow: hidden;
    position: relative;
    z-index: 10;
    transition: opacity 500ms ease-out;
  }

  &.is-playing .o-video-reveal__content {
    opacity: 0;
  }

  .o-video-reveal__video,
  canvas,
  iframe {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }

  canvas {
    opacity: .75;
    z-index: 1;
  }

  @media (min-aspect-ratio: 16/9) {
    .o-video-reveal__video { height: 500%; top: -200%; }
  }
  @media (max-aspect-ratio: 16/9) {
    .o-video-reveal__video { width: 500%; left: -200%; }
  }

  .o-video-reveal__button[disabled]{
    opacity: .25;
  }

  .o-video-reveal__button:focus {
    outline: 0;
  }

}