$widths:
  '1' '1'   100%,
  '1' '2'   50%,
  '1' '3'   33.33333%,
  '2' '3'   66.66666%,
  '1' '4'   25%,
  '3' '4'   75%,
  '2' '5'   40%,
  '3' '5'   60%,
  '1' '6'   16.6667%,
  '5' '6'   83.3333% !default;
