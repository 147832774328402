.a-notification {
  font-family: EffraBold;
}

.a-notification--succes {
  color: get-color('brand');
}

.a-notification--error {
  color: get-color('error');
}
