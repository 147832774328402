/*------------------------------------*\
  #HERO IMAGES
\*------------------------------------*/

.o-hero {
  position: relative;
  width: 100%;
  margin-top: 0;

  .o-hero__image  {
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    img,
    figure {
      visibility: hidden;
      opacity: 0;
      margin-top: 0;
    }
  }
}

/*------------------------------------*\
  #HERO IMAGES SMALL
\*------------------------------------*/

.o-hero--small {

  .o-hero__image {
    height: baseline(15.5);
  }

  + .o-article {
    padding: baseline(2) 0;
  }
}

/*------------------------------------*\
  #HERO IMAGES LARGE
\*------------------------------------*/

.o-hero--large {

  /*
  * 1. Workaround for ie10 / ie11
  */

  .o-hero__image {
    height: 100vh; /* [1] */
    max-height: 100vh;
  }
}