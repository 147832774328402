.o-scroller {
  padding: baseline(1);
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%) translateX(0%);
  z-index: 20;
  display: none;

  ul {
    @include list-bare;
  }

  li + li {
    margin-top: baseline(.5);
  }

  a,
  a::before {
    display: block;
    border-radius: 50%;
  }

  a {
    white-space: nowrap;
    height: 1em;
    width: 1em;
    position: relative;

    &::before {
      background: get-color('light-brand');
      content: '';
      width: .5em;
      height: .5em;
      position: absolute;
      top: 50%;
      left:50%;
      transform: translateY(-50%) translateX(-50%);
    }

    &.o-scroller__active {
      border: 2px solid get-color('light-brand');
    }
  }

  @include respond-min(l) {
    display: block;
  }

}