/*------------------------------------*\
  #CONTAINER
\*------------------------------------*/

.a-container {
  width: 100%;
  max-width: $layout-max-width;
  margin: 0 auto;
  padding-left: baseline(1.25);
  padding-right: baseline(1.25);
  position: relative;

  //> *:first-child {
  //  margin-top: 0;
  //}

  @include respond-min('m') {
    padding-left: baseline(2.25);
    padding-right: baseline(2.25);
  }

  @include respond-min('l') {
    padding-left: baseline(1);
    padding-right: baseline(1);
  }

  &--small {
    width: 100%;

    @include respond-min(m) {
      max-width: $layout-max-width-small;
    }
  }

  &--medium {
    width: 100%;

    @include respond-min(l) {
      max-width: $layout-max-width-medium;
    }
  }

  &--large {
    width: 100%;

    @include respond-min(l) {
      max-width: 100%;
    }
  }
}