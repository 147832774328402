.o-about {
  .m-layout__item:first-child {
    margin-top: 0;
  }

  @include respond-min(l) {
    .m-layout__item {
      margin-top: 0;
    }
  }

  .a-about__content p {
    margin-top: 0;
  }

  .a-about__video {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}