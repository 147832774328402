.a-meta {
  color: get-color('dark-grey');
}

.a-meta--small {
  font-size: 80%;
}

.a-meta--caps {
  text-transform: uppercase;
}