.o-quote {

  .o-quote__quote {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .m-layout__item:first-child {
    margin-top: 0;
  }

  .o-quote__avatar {
    text-align: center;

    img {
      margin-top: 0;
    }
  }

  @include respond-min(m) {
    .o-quote__avatar {
      text-align: left;
    }

    .m-layout__item {
      margin-top: 0;
    }
  }
}