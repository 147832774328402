.a-box {
  position: relative;
}

.a-box--picture {
  background-position: center center;
  background-size: cover;
  text-shadow: 1px 1px 1px get-color('black');
}

.a-box--bleed-left,
.a-box--bleed-right {

  &::after {
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    left: 100%;
    top: 0;
  }

  @include respond-min(xl) {
    width: 999em;
  }

}

.a-box--bleed-x {
  margin-bottom: baseline(-2);
  z-index: 1;
}

.a-box--bleed-left {
  &::after {
    left: auto;
    right: 100%;
  }
}

.a-box--brand {
  &,
  &::after {
    background-color: get-color('brand');
  }
}

.a-box--white {
  &,
  &::after {
    background-color: get-color('white');
  }
}

.a-box--grey {
  &,
  &::after {
    background-color: get-color('grey');
  }
}

.a-box--light-grey {
  &,
  &::after {
    background-color: get-color('light-grey');
  }
}

.a-box--dark-grey {
  &,
  &::after {
    background-color: get-color('dark-grey');
  }
}