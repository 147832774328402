/*------------------------------------*\
    #FORMS
\*------------------------------------*/

form {
  // styles
  background: inherit;
}

fieldset {
  // styles
  background: inherit;
}

label {
  display: block;
  font-weight: bold;
}

legend {
  font-weight: bold;
  margin-bottom: 0;
}

input,
textarea,
select {
  width: 100%;

  &[disabled] {
    opacity: .5;
  }
}

[type="radio"],
[type="checkbox"] {
  width: auto;

  + label {
    display: inline-block;
    font-weight: normal;
  }

  &[disabled] + label {
    opacity: .5;
  }

}