.o-locations-stack {

  .o-locations-stack__map {

    img {
      width: 100%;
    }

  }

  .o-locations-stack__selector {
    margin: baseline(2) 0;
    position: relative;

    label {
      background-color: get-color('white');
      display: block;
      font-family: $font-heading-regular;
      font-weight: $font-weight-regular;
      line-height: 1.4em;
      padding: baseline(.5);
      text-align: center;
      text-transform: uppercase;

      .a-icon {
        margin-left: baseline(.5);
      }

    }

    select {
      margin-top: 0;
      opacity: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }

  }

  @include respond-min('m') {
    min-height: 100vh;
    padding-bottom: 0;

    .o-locations-stack__header {
      width: auto;
      opacity: 1;
      pointer-events: none;
      position: relative;
      transition: opacity 500ms ease-out;
      z-index: 1;

      &.is-hidden {
        opacity: 0;
      }

      span {
        float: right;
      }
    }


    .o-locations-stack__selector {
      display: none;
    }

    .o-locations-stack__map {
      bottom: 0;
      left: 0;
      top: 0;
      margin-top: 0;
      position: absolute;
      width: 100%;

      .o-locations-stack__map-image {
        display: none;
      }

    }

    // Google Maps component

    .o-google-maps {
      height: 100%;
      margin-top: 0;
      position: absolute;
      width: 100%;

      > div {
        background-color: get-color('brand') !important;
      }

      .o-google-maps__controls {
        background-color: transparent !important;
        bottom: baseline(1);
        position: absolute;
        left: 50%;
        width: 105px;
        transform: translateX(-50%);
        margin-top: 0;
        overflow: hidden;

        .a-button {
          float: left;

          + .a-button {
            margin-left: baseline(.5);
            margin-top: 0;
          }
        }

      }

    }

  }

}

.gm-style {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;

  * + * {
    margin-top: 0;
  }

  [style*="MarkerCluster.png"]{
    line-height: 38px !important;
    padding-left: 36px !important;
  }

}

.a-marker {
  background: url('../img/marker.png');
  display: block;
  height: 70px;
  transform-origin: 50% 100%;
  width: 70px;
  cursor: pointer;

  &:hover {
    background-image: url('../img/markerHover.png');
    opacity: 1;
  }

}

.a-marker__window {

}

.cluster > div {
  line-height: 43px !important;
  padding-left: 31px !important;
}

.o-page-main {

  > * {

    &:first-child {

      .o-locations-stack__map {
        margin-top: baseline(4);
      }
      .o-locations-stack__header {
        margin-top: baseline(2);
      }
    }
  }
}