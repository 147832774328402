.o-video {
  background: get-color('brand');
  margin-top: 0;
  //position: relative;
  //padding-bottom: 56.25%; /* 16:9 => (100/16 * 9) */
  //height: 0;
  height: 100vh;
  min-height: 100vh;

  .o-video__placeholder,
  iframe {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

}

.o-video__img {
  width: 100%;
  //background-position: center center;
  //background-repeat: no-repeat;
  //background-size: cover;

  img,
  figure {
    visibility: hidden;
    opacity: 0;
    margin-top: 0;
  }
}

.o-video__placeholder {
  background-color: transparent;
  border-color: transparent;
  color: get-color('white');
  margin-top: 0;
  transition: opacity .3s ease;
}

.o-video__placeholder img {
  display: block;
  margin: 0;
  max-width: none;
  width: 100%;
}

.o-video__placeholder-icon {
  background: get-color('brand');
  margin-top: 0;
  font-size: 3rem;
  line-height: 1;
  position: absolute;
  padding: .25em;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: get-color('white');
  opacity: .8;
}

.o-video__player {
  margin-top: 0;
}

.o-video__placeholder:hover .o-video__placeholder-icon {
  opacity: 1;
}