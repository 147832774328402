/* Utils for ratios
----------------------------------------------- */

.u-ratio {
  position: relative;

  > * {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

}

// defaults
@each $antecedent, $consequent in $ratios {

  @if (type-of($antecedent) != number) {
    @error "`#{$antecedent}` needs to be a number."
  }

  @if (type-of($consequent) != number) {
    @error "`#{$consequent}` needs to be a number."
  }

  .u-ratio--#{$antecedent}\:#{$consequent} {
    padding-bottom: ($consequent/$antecedent) * 100%;
  }

}
