/*------------------------------------*\
    #LINKS
\*------------------------------------*/

a {
  color: get-color('brand');
  text-decoration: underline;

  @include attention() {
    text-decoration: none;
  }

}

.a-link {
  display: inline-block;
}
