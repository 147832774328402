.u-crop {
  overflow: hidden;
  position: relative;

  > * {
    bottom: auto;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    left: 0;
    position: absolute;
    top: 0;
    width: auto;
  }

}