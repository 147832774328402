.o-natural-language {
  font-family: $font-heading-light;

  .o-natural-language__question {
    font-size: 130%;
    color: white;
    line-height: baseline(2);
    display: inline;
  }

  .m-form__item {
    margin-right: baseline(.5);
    padding:0;
  }

  .a-select {
    background-color: transparent;
    border-width: 0 0 1px;
    font-style: italic;
    font-size: 110%;
    padding-right: baseline(.75);

    select {
      font-family: $font-heading-light;
      font-style: italic;
    }
  }

  .a-select__label {
    margin: 0;
  }

  .a-button {
    display: block;
  }

  .a-link {
    margin-top: baseline(1);
  }

  @include respond-min(m) {
    .o-natural-language__question {
      font-size: 200%;
      color: white;
    }

    .a-select {
      //border-width: 0 0 1px;
      //font-style: italic;
      font-size: 130%;
      padding-right: baseline(1);
    }

  }

}

@include respond-max(m) {
  .o-natural-language .a-h2 {
    font-size: 130%;
  }
}