.o-vacancy-list-related {

  .o-vacancy-list-related__button {
    text-align: center;
  }
}

.a-stack--brand {

  .o-vacancy-list-related__text {
    color: white;
  }
}

