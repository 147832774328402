/* Cookiebar styling
---------------------------------------- */
.o-cookie-bar {
  color: get-color('white');
  background-color: rgba(get-color('black'), .9);
  padding-top: 0;
  padding-bottom: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 500;

  &.o-cookie-bar--hidden {
    height: 0;
    overflow: hidden;
    //transition: all $default-duration $default-easing;

    .o-cookie-bar__wrapper {
      transform: translateY(100%);
      //transition: all $default-duration $default-easing;
    }
  }
}

.o-cookie-bar__wrapper {
  padding-top: baseline(.5);
  padding-bottom: baseline(.5);

  .m-layout__item {
    margin-top: 0;
  }
}

.o-cookie-bar__content {

  p {
    font-weight: 100;
    font-size: 70%;
    display: inline-block;
    margin-top: 0;
    vertical-align: middle;

    @include respond-min('m'){
      margin-top: 0;
    }
  }

  a + p {
    margin-top: 0;
  }
}

.o-cookie-bar__close {
  //margin-right: baseline(.25);
  padding: baseline(.5);
  text-decoration: none;
  float: right;
}

//.o-cookie-bar__buttons {
//  line-height: baseline(1.75);
//
//  .o-cookie-bar__close {
//    margin-right: baseline(.25);
//    padding: baseline(.5);
//  }
//
//  @include respond-min('m'){
//    margin-top: 0;
//  }
//}

