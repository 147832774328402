/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/

/* Basic vertical rhythm
----------------------------------------------- */

@for $i from 1 through length($breakpoints) {
  $bp: nth($breakpoints, $i);
  $bp-name: nth($bp, 1);
  $bp-code: nth($bp, 2);

  @if map-has-key($typographic-breakpoints, $bp-name) {
    $scope: map-get($typographic-breakpoints, $bp-name);

    @if $i > 1 {

      @include respond-min($bp-name) {

        * + * {
          margin-top: stack('m');
        }

      }

    } @else {

      * + * {
        margin-top: stack('m');
      }

    }

  }

}

/* Create silent selectors for headings.
----------------------------------------------- */

@for $i from 1 through length($breakpoints) {
  $bp: nth($breakpoints, $i);
  $bp-name: nth($bp, 1);
  $bp-code: nth($bp, 2);

  @if map-has-key($typographic-breakpoints, $bp-name) {
    $scope: map-get($typographic-breakpoints, $bp-name);

    // first breakpoint should be without media-query
    @if $i > 1 {

      @include respond-min($bp-name) {

        @each $scale in $typographic-scale-names {
          $scale-name: nth($scale, 1);
          $scale-scale: nth($scale, 2);
          $scale-extra-line-height: $scale-scale;

          %#{$scale-name} {
            @include calculate-scale($bp-name, $scale-name, $scale-extra-line-height);
          }

        }

      }

    } @else {

      @each $scale in $typographic-scale-names {
        $scale-name: nth($scale, 1);
        $scale-scale: nth($scale, 2);
        $scale-extra-line-height: $scale-scale;

        %#{$scale-name} {
          @include calculate-scale($bp-name, $scale-name, $scale-extra-line-height);
        }

      }

    }

  }

}

/* Headings
----------------------------------------------- */

body {
  color: get-color('brand-dark');
}

p {
  @extend %base;
}

.lead {
  font-family: $font-heading-bold;
}

small,
.small {
  @extend %small;
}

.x-small {
  @extend %xsmall;
}

h1,
h2,
h3,
h4,
h5,
p {
  @extend %base;
}

h1,
h2,
h3,
h4,
h5,
.a-h1,
.a-h2,
.a-h3,
.a-h4,
.a-h5 {
  color: get-color('brand');
  font-family: $font-heading-bold;

  .a-header--sub {
    display: block;
    font-family: $font-heading-light;
    font-size: 72%;
    line-height: 172%;
  }
}

@mixin h1 {
  @extend %h1;
}

@mixin h2 {
  @extend %h2;
}

@mixin h3 {
  @extend %h3;
}

@mixin h4 {
  @extend %h4;
}
//
//@mixin h5 {
//  @extend %h5;
//}

h1,
.a-h1 {
  @include h1;
}

h2,
.a-h2 {
  @include h2;
}

h3,
.a-h3 {
  @include h3;
}

h4,
.a-h4 {
  @include h4;
}
//
//h5,
//.a-h5 {
//  @include h5;
//}

/* Headings Modifiers
----------------------------------------------- */

.a-header--center {
  text-align: center;
}

.a-header--light {
  font-family: $font-heading-light;
  font-weight: 200;
}

.a-header--line {
  @include clearfix;

  span:first-child {
    border-top: 3px solid get-color('brand');
    float: left;
    padding-top: baseline(.25);

    .u-diapositive &{
      border-color: get-color('white');
    }

  }

  span:last-child  {
    margin-top: 0;
    clear: both;
    display: block;
  }

  &.u-diapositive span:first-child {
    border-top-color: get-color('white');
  }

}

/* Set base font-sizes configured in $typographic-breakpoints.
----------------------------------------------- */

$base: 16; // browser default

@each $bp-name, $bp-size in $breakpoints {

  $bp-config: map-get($typographic-breakpoints, $bp-name);

  @if not $bp-config {
    // @warn 'No value found for `#{$bp-name}`. Please make sure it is defined in `$typographic-breakpoints` map.';
  }

  @if map-has-key($typographic-breakpoints, $bp-name) {

    $bp: map-get($breakpoints, $bp-name);
    $font-size: map-get($bp-config, 'font-size');
    $line-height: map-get($bp-config, 'line-height');

    @include respond-min($bp-name) {

      html {
        font-size: ($font-size / $base) * 100%;
        line-height: $line-height;
      }

    }

  }

}