.o-location-stack {
  padding-top: 0;
  padding-bottom: 0;

  .o-google-maps {
    min-height: baseline(15);

    @include respond-min('m'){
      min-height: baseline(20);
    }

  }


}