/*------------------------------------*\
    #MARGINS BOTTOM
\*------------------------------------*/
// scss-lint:disable SelectorFormat

.u-mb {
    margin-bottom: baseline(1);
}

.u-mb\+ {
    margin-bottom: baseline(2);
}

.u-mb\+\+ {
    margin-bottom: baseline(3);
}

/*------------------------------------*\
    #MARGINS TOP
\*------------------------------------*/

.u-mt {
    margin-top: baseline(1);
}

.u-mt\+ {
    margin-top: baseline(2);
}

.u-mt\+\+ {
    margin-top: baseline(3);
}