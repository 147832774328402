/*------------------------------------*\
    #SELECTION
\*------------------------------------*/

/**
 * Selected/highlighted text.
 */

::selection {
  // background: #FF0000;
  // color: #00FF00;
  // text-shadow: none;
}