.o-location-flag {
  background-color: get-color('white');
  color: get-color('white');
  box-shadow: 0 baseline(.25) baseline(1) rgba(get-color('black'), .333);
  width: baseline(10);
  margin-top: baseline(-1.75);

  .o-location-flag__wrapper,
  .o-location-flag__header,
  h3 {
    position: relative;
  }

  .o-location-flag__close {
    color: get-color('white');
    position: absolute;
    right: 0;
    top: 0;
    z-index:2;
  }

  .o-location-flag__header {
    display: block;
    height: baseline(7);
    margin-top: 0;
    width: 100%;

    h3 {
      //width: 66.666%;
      z-index: 1;
      height: 100%;
      color: get-color('white');
      background-color: rgba(get-color('brand'), .8);
      padding: baseline(.5);
    }
  }

  .o-location-flag__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 0;

    img {
      margin: 0;
    }
  }

  .o-location-flag__btn {
    margin-top: 0;
    text-align: center;
  }
}