/*------------------------------------*\
    #HIDE
\*------------------------------------*/

/**
 * Hide elements for visual users, but still make them available to screenreaders.
 * Use the --focusable modifier class to show items when focussed via keyboard.
 */

.u-hidden {
  display: none;
}

.u-visually-hidden,
.u-screenreader {
  @include hide();

  &--focusable:active,
  &--focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    white-space: nowrap;
    width: auto;
  }
}

