/*------------------------------------*\
  #STACKS
\*------------------------------------*/

.a-stack {
  margin: 0;
  padding: baseline(2) 0;
  position: relative;

  &--large {
    padding: baseline(3.5) 0;
  }

  &--center {
    text-align: center;
  }

}

.js .a-stack {

  &.in-view {

    .a-container {
      opacity: 0;
    }

  }

  &.in-view--initialized {

    .a-container {
      opacity: 0;
      transition: all 500ms ease-out 500ms;
      transform: translateY(baseline(2))
    }

  }

  &.in-view--active {

    .a-container {
      opacity: 1;
      transform: translateY(0);
      z-index: 1;
    }
  }

}

/*------------------------------------*\
  #STACK FULL SIZE VIEWPORT
\*------------------------------------*/

@include respond-min(l) {

  /*
  * 1. Flexbug workaround IE10 / IE11
  * 2. Reset padding because stack is larger then viewport
  */

  .a-stack--full-size {
    display: flex;
    flex-flow: column wrap;
    padding: 0; /* [2] */

    .a-container {
      align-items: center;
      display: flex;
      min-height: 100vh;
      flex-flow: column wrap;
      justify-content: center;
    }

    [class*="wrapper"] {
      width: 100%; /* [1] */
    }
  }
}

/*------------------------------------*\
  #STACK COLOURS
\*------------------------------------*/

.a-stack--brand {
  background-color: get-color('brand');
}

.a-stack--white {
  background-color: get-color('white');
}

.a-stack--grey {
  background-color: get-color('grey');
}

.a-stack--light-grey {
  background-color: get-color('light-grey');
}

.a-stack--dark-grey {
  background-color: get-color('dark-grey');
}
