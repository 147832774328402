/* 01 Base
--------------------------------------*/

@import '../../../ui/base/scss/base';

/* 02 Components
--------------------------------------*/

@import '../../../ui/components/Component/Component';
@import '../../../ui/components/Component/ComponentVariant';

@import '../../../ui/components/PageHeader/PageHeader';
@import '../../../ui/components/PageFooter/PageFooter';
@import '../../../ui/components/LanguageSwitch/LanguageSwitch';
@import '../../../ui/components/Search/Search';
@import '../../../ui/components/SearchAdvanced/SearchAdvanced';
@import '../../../ui/components/Filter/Filter';
@import '../../../ui/components/VacancyCard/VacancyCard';
@import '../../../ui/components/VacancyList/VacancyList';
@import '../../../ui/components/NaturalLanguageForm/NaturalLanguageForm';
@import '../../../ui/components/Scroller/Scroller';
@import '../../../ui/components/ShareStack/ShareStack';
@import '../../../ui/components/ArticleBody/ArticleBody';
@import '../../../ui/components/RecruiterStack/RecruiterStack';
@import '../../../ui/components/VacancyListRelated/VacancyListRelated';
@import '../../../ui/components/VacancyBody/VacancyBody';
@import '../../../ui/components/Hero/Hero';
@import '../../../ui/components/ScrollingHint/ScrollingHint';
@import '../../../ui/components/HeaderImageStack/HeaderImageStack';
@import '../../../ui/components/LocationsStack/LocationsStack';
@import '../../../ui/components/LocationStack/LocationStack';
@import '../../../ui/components/LocationFlag/LocationFlag';
@import '../../../ui/components/TwoColContentStack/TwoColContentStack';
@import '../../../ui/components/ContactStack/ContactStack';
@import '../../../ui/components/GalleryStack/GalleryStack';
@import '../../../ui/components/JobAlertForm/JobAlertForm';
@import '../../../ui/components/Pager/Pager';
@import '../../../ui/components/AboutStack/AboutStack';
@import '../../../ui/components/VideoReveal/VideoReveal';
@import '../../../ui/components/LinkedinStack/LinkedinStack';
@import '../../../ui/components/VideoSlider/VideoSlider';
@import '../../../ui/components/Video/Video';
@import '../../../ui/components/QuoteStack/QuoteStack';
@import '../../../ui/components/CookieBar/CookieBar';

/* 03 Trumps
--------------------------------------*/

@import '../../../ui/base/scss/shame';

