/*------------------------------------*\
    #LISTS
\*------------------------------------*/

ul,
ol {
    list-style-position: outside;
    padding-left: baseline(1);

    > li {
        margin-top: 0;
    }
}

ul ol,
ol ul,
ul ul {
    margin-bottom: 0;
    padding-left: baseline(1);
}

dl {
    dt {
        font-weight: 600;
    }
}