.o-scroll-hint-stack {
  background: rgba(get-color('brand'), .75);
  //padding: baseline(1) 0;
  text-align: center;
  margin-top: 0;

  .o-scroll-hint-stack__btn {
    padding: baseline(1) 0;
    text-decoration: none;
    display: block;
    position: relative;
    height: baseline(2.75);
  }

  .a-icon {
    line-height: 1;
    left: 50%;
    transform: translateY(-50%);
    position: absolute;
  }

  .o-header-stack--image &,
  .a-stack & {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .o-scroll-hint-stack__target {
    margin-top: 0;
    height: 0;
    position: absolute;
    top: 100%;
  }
}

.o-scroll-hint-stack--transparent {
  background-color: transparent;
}
