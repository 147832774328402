/*------------------------------------*\
    #TYPOGRAPY-SETTINGS
\*------------------------------------*/

$font: Effra, Arial, sans-serif;
$font-heading-bold: EffraBold, Arial, sans-serif;
$font-heading-regular: Effra, Arial, sans-serif;
$font-heading-light: EffraLight, Arial, sans-serif;
$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;
