// Arrow helper mixin
// ---
// @param [string] $direction: arrow direction
// @param [list] $position: list of offsets and values
// @param [color] $color (inherit): arrow color
// @param [number] $size (1em): arrow size
// ---

@mixin triangle($direction, $position, $color: currentColor, $size: 1em) {

  // Make sure the direction is valid
  @if not index(top right bottom left, $direction) {
    @warn 'Direction must be one of top, right, bottom or left.';
  } @else {
    @include absolute($position); // Position
    @include size(0); // Size
    content: '';
    z-index: 2;

    border-#{opposite-direction($direction)}: $size * 1.5 solid $color;
    $perpendicular-borders: $size solid transparent;

    @if $direction == top or $direction == bottom {
      border-left: $perpendicular-borders;
      border-right: $perpendicular-borders;
      margin-left: -$size;
    } @else if $direction == right or $direction == left {
      border-bottom: $perpendicular-borders;
      border-top: $perpendicular-borders;
      margin-top: -$size;
    }

  }

}
