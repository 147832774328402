.o-recruiter {
  color: get-color('brand');

  .a-list--social {

    a {
      text-decoration: none;
    }
  }

  .o-recruiter__avatar {
    text-align: center;

    img {
      margin-top: 0;
    }
  }

  .m-layout__item:first-child {
    margin-top: 0;
  }

  .name,
  .telephone,
  .email {
    display: block;
    margin: 0;
  }

  @include respond-min(m) {
    .o-recruiter__avatar {
      text-align: left;
    }

    .name,
    .telephone {
      display: inline;
    }

    .m-layout__item {
      margin-top: 0;
    }
  }
}