/*------------------------------------*\
    #LIST-BORDER
\*------------------------------------*/

/**
* The list-border object adds a border to every list-item.
*/

@mixin list-border {

    > li {
        border-top: 1px solid currentColor;

        &:last-child {
            border-bottom: 1px solid currentColor;
        }
    }

}

.a-list-border,
%list-border {
    @include list-border;
}