/* Language switch specific styling for mobile/table */

@include respond-max('l') {

  .o-language-switch {
    display: block;

    ul {
      list-style: none none outside;
    }

    li + li {
      border-top: 1px solid #f1f1f1;
    }

    button,
    a {
      color: get-color('brand');
      display: block;
      padding: baseline(.5);
      position: relative;
      text-align: left;
      text-decoration: none;
      text-transform: uppercase;
      width: 100%;
      font-size: 120%;

      .a-icon {
        margin-top: 0;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

    }

    button {
      border-bottom: 1px solid get-color('brand');
    }

    // Language switch toggle
    .o-language-switch__trigger {
      background-color: transparent;
      border-top: 1px solid get-color('brand');
      border-width: 1px 0 0;
      color: get-color('brand');
      display: block;
      position: relative;
      text-align: left;
      width: 100%;

      .a-icon {
        margin-top: 0;
        position: absolute;
        right: baseline(1);
        top: 50%;
        transform: translateY(-50%);
      }

    }

    a {
      @include attention {
        background-color: get-color('brand');
        color: get-color('white')
      }
    }

    // Active state of language switch
    &.activated {
      top: 0;

      // When language switch is active, header is visible
      .o-language-switch__header {
        display: block;
        margin-top: 0;
      }

      // Trigger
      .o-language-switch__trigger {
        border-top-width: 0;
        border-bottom-width: 1px;
      }

      // When language switch is active, all options are visible
      .o-language-switch__languages {
        display: block;
        position: relative;
      }

    }


  }

}

/* Language switch specific styling for desktop */

@include respond-min('l') {

  .o-language-switch {
    border: 1px solid transparent;
    margin-top: -1px; // compensate for border
    width: 5.5em;

    button {
      width: 100%;
      color: get-color('white');
    }

    button,
    a {
      font-size: 80%;
      font-weight: $font-weight-light;
      letter-spacing: .1rem;
      padding: baseline(.5);
      text-align: left;
      position: relative;

      .a-icon {
        position: absolute;
        right: baseline(.5);
        top: 50%;
        transform: translateY(-50%);
      }
    }

    a {
      padding: baseline(.5);
      text-decoration: none;
      position: relative;
    }

    &.is-active {
      background-color: get-color('brand');
      border-color: get-color('white');

      .o-language-switch__languages {
        background-color: get-color('brand');
        border: 1px solid get-color('white');
        display: block;
        left: -1px;  // compensate for border
        min-width: 0;
        right: -1px; // compensate for border
        width: auto;
      }

      a {

        @include attention {
          background-color: get-color('white');
          color: get-color('brand');
        }

      }

    }

  }

  .o-page-header--diapositive,
  .o-page-header--white {

    .o-language-switch {

      button {
        color: get-color('brand');
      }

      .o-language-switch__languages {
        border-color: get-color('brand');
      }

      &.is-active {
        border-color: get-color('brand');

        button,
        a {
          color: get-color('white');
        }

        a {
          @include attention {
            color: get-color('brand')
          }
        }

      }

    }

  }

}

