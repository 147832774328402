/* Page Header (transparent)
---------------------------------------------------------*/
.o-page-header {
  background-color: transparent;
  left: 0;
  margin-top: 0;
  padding: baseline(1);
  position: absolute;
  transition: all 250ms ease-out;
  transform: translateY(0%);
  top: 0;
  z-index: 11;
  width: 100%;

  &.has-no-animation {
    transition: opacity 0ms ease-out, transform 0ms ease-out; // no animation, otherwise will show switch from fixed to non-fixed
  }

  // when scrolling to bottom the header dissappears

  &.is-fixed {
    position: fixed;
    transform: translateY(-100%);
  }

  // when scrolling to top header shows, in white 90%

  &.is-fixed.is-visible {
    transform: translateY(0%);
  }

  .o-page-header__logo {
    float: left;
    height: baseline(1.5);
    margin: 0;
    overflow: hidden;
    position: relative;
  }

  // logo is blue

  .o-page-header__logo svg {
    height: baseline(1.5);
    fill: get-color('brand');
    vertical-align: top;
  }

  // links etc blue

  a {
    color: get-color('brand');
  }


  // quick links: hamburger and search

  .o-page-header__skip-links {
    float: right;
    margin-top: 0;

    ul {
      @include list-nav;
      margin-top: 0;
    }

    li + li {
      margin-left: get-spacing('inline', 's');
    }

    // icons

    a {
      text-decoration: none;
      text-transform: uppercase;
      display: inline-block;
      margin-top:-1px;
      font-family: $font-heading-light;
    }

    // hide o-page-header__skip-link-menu for visual users viewport large(desktop)

    @include respond-min(l){

      .o-page-header__skip-link-search a {
        padding: baseline(.5) baseline(1);
        font-size: 80%;
      }

      .a-icon--magnify {
        font-size: 100%;
      }

      .o-page-header__skip-link-search {
        margin-left: 0;
      }

    }
  }
}

/* Page Header transparent used for homepage etc
---------------------------------------------------------*/

.o-page-header--transparent {
  background-color: transparent;

  // logo is blue

  .o-page-header__logo svg {
    fill: get-color('white');
  }

  a {
    color: get-color('white');
  }
}


/* Page Header (blue)
---------------------------------------------------------*/

.o-page-header--brand {
  background-color: rgba(get-color('brand'), .97);

  // logo is blue

  .o-page-header__logo svg {
    fill: get-color('white');
  }

  // links etc white

  a {
    color: get-color('white');
    outline: 0;
  }

}

/* Page Header (white)
---------------------------------------------------------*/
.o-page-header--diapositive {
  background-color: rgba(get-color('white'), .97);
  border-bottom: 1px solid get-color('grey');

  a {
    color: get-color('brand');
  }
}

/* Page Header (white)
---------------------------------------------------------*/

.o-page-header--white {
  background-color: rgba(get-color('white'), .97);
  border-bottom: 1px solid get-color('grey');

  a {
    color: get-color('brand');
  }
}

/* Navigation panel (mobile: overlay)
---------------------------------------------------------*/

.o-panel {

  .o-panel__header,
  .o-panel__content,
  .o-panel__footer {
    margin-top: 0;
  }

  .o-panel__header {
    overflow: hidden;
    padding: baseline(1);
    position: relative;

    .o-panel__logo {
      float: left;
      height: baseline(1.5);
      margin: 0;
      overflow: hidden;
      position: relative;

      svg {
        fill: get-color('brand');
        height: baseline(1.5);
      }

    }

    .o-panel__close {
      color: get-color('brand');
      position: absolute;
      right: baseline(1);
      top: 50%;
      margin-top: 0;
      transform: translateY(-50%);
      background-color: transparent;
      border: 0;
      padding: 0;
      font-size: 120%;

      .u-diapositive & {
        color: get-color('brand');
      }
    }

  }

  .o-menu__list a,
  .o-language-switch button,
  .o-language-switch a,
  .o-panel__footer button {
    font-family: $font-heading-light;
  }
}

.o-panel__overlay {
  background: rgba(get-color('black'), .9);
  height: 100vh;
  left: 0;
  margin-top: 0;
  position: fixed;
  opacity: 0;
  transition: opacity 500ms ease-out;
  top: 0;
  width: 100%;
  z-index: -1;
  pointer-events: none;
  display: none;
}

@include respond-max('l') {

  .o-panel {
    background: rgba(get-color('white'), .97);
    height: 100vh;
    left: 0;
    margin-top: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    transform: translate3d(-100%, 0%, 0);
    transition: transform 500ms ease-out, opacity 1ms ease-out 500ms;
    top: 0;
    width: 100%;

    &.activated {
      opacity: 1;
      pointer-events: inherit;
      transform: translate3d(0%, 0%, 0);
      transition: transform 500ms ease-out, opacity 1ms ease-out 0ms;
    }

  }

  .o-panel--language {
    //transform: translate3d(0%, 100%, 0);
    transform: translate3d(100%, 0, 0);
  }

  // Menu panel
  .o-panel--menu {
  }

  .o-panel__close {
    color: get-color('white');
  }


}


/* Navigation panel (desktop: inline items)
---------------------------------------------------------*/

@include respond-min('l'){

  .o-panel {
    float: right;
    margin-top: 0;

    // hide header & footer (footer only in use for language toggle right now)
    .o-panel__header,
    .o-panel__footer {
      display: none;
    }

    .o-panel__content {
      margin-top: 0;
    }

  }

}

/* Main navigation (mobile: overlay)
---------------------------------------------------------*/

@include respond-max('l'){

  .o-panel--menu {

    /*
     * Menu-items
     */

    li {
      display: block;
    }

    /*
     * 1. Be visible on Mobile, hidden on desktop
     */

    li.o-menu__link-search {
      display: block; /* [1] */
    }

    .o-menu__list {
      a,
      button {
        color: get-color('brand');
        display: block;
        padding: baseline(.5) baseline(1);
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 120%;

        @include attention {
          background-color: get-color('brand');
          color: get-color('white');
        }

        .a-icon--caret-right,
        .a-icon--caret-top {
          position: absolute;
          right: baseline(1);
          top: 50%;
          transform: translateY(-50%);
        }
      }


    }

    li.o-menu__link-languages {
      button {
        width: 100%;
        text-align: left;
        font-family: $font-heading-light;
        font-weight: $font-weight-light;
      }
    }

    .o-panel__content {
      margin-top: baseline(2);
    }

    /* Language switch link */

    //.o-panel__footer {
    //  bottom: 0;
    //  left: 0;
    //  position: absolute;
    //  width: 100%;
    //
    //  button {
    //    background: get-color('white');
    //    border-top: 1px solid get-color('brand');
    //    color: get-color('brand');
    //    display: block;
    //    text-align: left;
    //    width: 100%;
    //    font-size: 120%;
    //    font-family: $font-heading-light;
    //
    //    .a-icon {
    //      margin-top: 0;
    //      position: absolute;
    //      right: baseline(1);
    //      top: 50%;
    //      transform: translateY(-50%);
    //    }
    //
    //  }
    //
    //}

  }

}

/* Main navigation (desktop: inline menu)
---------------------------------------------------------*/

@include respond-min('l'){

  .o-panel--menu {

    li {
      display: inline-block;

      &.o-menu__link-search,
      &.o-menu__link-languages {
        display: none; /* [1] */
      }
    }

    a {
      text-decoration: none;
      display: block;
      letter-spacing: .1rem;
      font-size: 80%;

      /* animated line under menu item width 0 */
      &::after {
        content: '';
        position: relative;
        left: 0;
        bottom: 0;
        display: block;
        height: 1px;
        width: 0;
        background-color: get-color('white') ;
        transition: width .5s ease;
      }

      /* on hover width 100% */
      @include attention {

        &::after {
          width: 100%;
        }
      }

      .a-icon--caret-right {
        display: none;
      }

      .o-page-header--diapositive &,
      .o-page-header--white &
      {
        &::after {
          background-color: get-color('brand') ;
        }
      }
    }

    /* active menu item width 100% */
    .active {
      a::after {
        width: 100%;
      }
    }

    /*
      * 1. All inline items have the same padding
    */

    a,
    .o-menu__language-switch {
      padding: baseline(.5) baseline(1);
      text-transform: uppercase;
    }

    .o-menu__language-switch {
      color: get-color('white');
      cursor: pointer;
    }

  }

}

/* Search (mobile: overlay)
---------------------------------------------------------*/

.o-panel--search {
  background-color: transparent;
  transform: translate3d(0%, -100%, 0);
  transition: transform 500ms ease-out;
  position: absolute;
  width: 100%;
  left:0;
  top: 0;

  &.activated {
    transform: translate3d(0%, 0%, 0);
  }

  .o-panel__header {
    background: get-color('brand');

    .o-panel__logo {

      svg {
        fill: get-color('white');
      }

    }

    .o-panel__close {
      color: get-color('white');
    }

  }

  .o-panel__header {
    display: block;
  }

  .o-panel__close {
    color: get-color('white');

    .x-small {
      font-family: $font-heading-light;
    }
  }

  .o-panel__content {
    background: get-color('brand');
    margin-top: 0;
    padding: baseline(2) 0;
    position: relative;
    z-index: 2;
  }

  .a-input--large {

    input {
      padding-left: 0;
    }
  }

  @include respond-min(l) {
    .a-input--large {

      input {
        padding-left: baseline(.5);
      }
    }

    .o-panel__close {
      color: get-color('white');

      .x-small {
        vertical-align: middle;
        margin-top: 0;
      }
    }
  }
}

/* Language switch (mobile: overlay)
---------------------------------------------------------*/

@include respond-max('l'){

  .o-panel--language {
    background: get-color('white');
    z-index: 2;

    .o-panel__header {
      background: get-color('brand');

      .o-panel__logo {

        svg {
          fill: get-color('white');
        }

      }

      .o-panel__close {
        color: get-color('white');
      }

    }

    .o-panel__content {
      padding: baseline(1);
    }

  }
}

/* Language switch (desktop: inline dropdown)
---------------------------------------------------------*/

@include respond-min('l') {

  .o-panel--language {

  }

}