.m-form--search {
  position: relative;

  input {
    margin-top: 0;
    padding-right: 50px; // TODO: width of icon-only button

    &::-ms-clear {
      display: none;
    }
  }

  .a-button {
    background-color: transparent;
    border-color: transparent;
    color: get-color('white');
    opacity: 1;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: 0;
    transform: translateY(-50%);
  }

  .a-button[type="reset"]{
    display: none;
  }
}