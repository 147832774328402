/*------------------------------------*\
    #Z-INDEX
\*------------------------------------*/

// inspired by https://gist.githubusercontent.com/fat/1f6da6b3bd0311a1f8a0/raw/b4920e953d449590f95e35676652d7ab67842866/gistfile1.less
//
//
// Medium.com's z-index scale. Z-index values should always be defined in z-index.less. This
// allows us to at a glance determine relative layers of our application and prevents bugs
// arrising from arbitrary z-index values. Do not edit the z-index scale! Only add application
// scoped z-index values.

// Z-Index Scale (private vars)
// --------------------------------------------------
$z-index-minus-1: -100;
$z-index-1:        100;
$z-index-2:        200;
$z-index-3:        300;
$z-index-4:        400;
$z-index-5:        500;
$z-index-6:        600;
$z-index-7:        700;
$z-index-8:        800;
$z-index-9:        900;
$z-index-10:      1000;


// Z-Index Applications
// --------------------------------------------------
$z-navigation: $z-index-2;
$z-service-navigation: $z-index-1;
$z-service-navigation-form: $z-index-minus-1;

$z-banner-header-row: $z-index-1;

$z-search-results-form: $z-index-1;

$z-social-sharing: $z-index-10;
