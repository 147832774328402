.a-intro,
.a-outro {
  margin-bottom: baseline(2);
  margin-left: auto;
  margin-right: auto;
  max-width: 40em;
  text-align: center;
}

.a-outro {
  margin-top: baseline(2);
}