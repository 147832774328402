/*------------------------------------*\
    #MAP-HAS-NESTED-KEYS
\*------------------------------------*/

/**
 * Map has nested keys
 * @author Hugo Giraudel
 * @access public
 * @param {Map} $map - Map
 * @param {Arglist} $keys - Key chain
 * @return {*} - Boolean
*/

@function map-has-nested-keys($map, $keys...) {
    @each $key in $keys {
      @if not map-has-key($map, $key) {
      @return false;
      }
      $map: map-get($map, $key);
    }

  @return true;
}