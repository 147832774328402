// Flickity

/*! Flickity v2.0.5
http://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  border: none;
  cursor: pointer;
  /* vertically center */
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.flickity-prev-next-button:hover { background: white; }

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F;
}

.flickity-prev-next-button:active {
  opacity: 0.6;
}

// .flickity-prev-next-button.previous { left: 10px; }
// .flickity-prev-next-button.next { right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  // right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  // left: 10px;
}

.flickity-prev-next-button:disabled {
  opacity: 0.3;
  cursor: auto;
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.flickity-prev-next-button .arrow {
  fill: #333;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

// Flickity end

// Generic slider styling

// Specific video slider styling


.o-video-slider {
  padding: 0;
  margin-top: 0;
  width: 100%;
  height: 100vh;
  min-height: 100vh;

  .a-button {
    margin-top: 0;
  }

}

.o-slider {
  @include list-bare;
  margin-top: 0;
  padding: 0;
  overflow: hidden;
}

.o-slider__item {
  margin-top: 0;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
}

.o-slider__img {
  margin-top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  img {
    visibility: hidden;
    opacity: 0;
    margin-top: 0;
  }
}

.o-slider__header {
  position: absolute;
  margin-top: baseline(2);
  color: get-color('white');
  z-index: 1;

  .a-meta-group {
    margin-top: 0;
  }

  .a-meta {
    color: get-color('white');
  }
}

// current slide
.o-slider__item.is-playing {

  .o-slider__header {
    display: none;
  }

}

/*
* Controls
*/

.o-slider__controls {
  margin-top: 0;
}

.a-control {
  display: block;
  margin-top: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-decoration: none;
  z-index: 1;

  @include attention {

    .a-control__label {
      max-width: 297px;
    }

  }

  .a-control__label {
    transition: all .3s ease;
    max-width: 0;
    overflow: hidden;
    height: baseline(1.5);
    background-color: get-color('brand');
    margin-top: 0;
    color: get-color('white');
    font-size: 70%;
    text-transform: uppercase;
    position: absolute;
    top:0;
    display: none;

    .a-control__label-inner {
      padding: baseline(.5) baseline(1);
      display: inline-block;
      line-height: 1;
      white-space: nowrap;
      margin-top: 0;
      vertical-align: top;
      font-family: 'EffraLight';
    }
  }
}

.a-control--next {
  right: 0;

  @include respond-min(m) {

    overflow: visible;

    .a-control__label {
      right: baseline(1.5);
      display: block;
    }
  }

  @include respond-min(l) {
    right: baseline(2.5);
  }
}

.a-control--previous {
  left: 0;

  @include respond-min(m) {

      overflow: visible;

      .a-control__label {
        left: baseline(1.5);
        display: block;
      }
  }

  @include respond-min(l) {
    left: baseline(2.5);
  }
}
