/* Icon base
----------------------------------------------- */

//.a-icon,
//[class^='a-icon--'],
//[class*=' a-icon--'] {
//  &::before,
//  &::after {
//    @include icon();
//  }
//
//  margin-top: 0;
//}

.a-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  @include icon();
}

/* Icon modifiers
----------------------------------------------- */

.a-icon--caret-top:before {
  content: "\e906";
}
.a-icon--check:before {
  content: "\e907";
}
.a-icon--bars:before {
  content: "\e900";
}
.a-icon--caret-bottom:before {
  content: "\e901";
}
.a-icon--caret-right:before {
  content: "\e902";
}
.a-icon--close:before {
  content: "\e903";
}
.a-icon--external-link:before {
  content: "\e904";
}
.a-icon--magnify:before {
  content: "\e905";
}
.a-icon--envelop:before {
  content: "\e90d";
}
.a-icon--caret-left:before {
  content: "\e90f";
}
.a-icon--minus:before {
  content: "\e910";
}
.a-icon--plus:before {
  content: "\e911";
}
.a-icon--arrow:before {
  content: "\e914";
}
.a-icon--open-video:before {
  content: "\e915";
}
.a-icon--phone:before {
  content: "\e912";
}
.a-icon--facebook:before {
  content: "\e908";
}
.a-icon--twitter:before {
  content: "\e909";
}
.a-icon--linkedin:before {
  content: "\e90a";
}
.a-icon--glassdoor:before {
  content: "\e90b";
}
.a-icon--xing:before {
  content: "\e90c";
}
.a-icon--weibo:before {
  content: "\e90e";
}

/* Icon sizes
----------------------------------------------- */

/*
*1. font-size 20px
*2. font-size 24px
*3. font-size 45px
*/

.a-icon--base {
  font-size: 80%; /* [1] */
}

.a-icon--medium {
  font-size: 120%; /* [2] */
  vertical-align: -15%;
}

.a-icon--large {
  font-size: 200%; /* [3] */
}

/* Icon stacked
----------------------------------------------- */

.a-icon--inline-spacing {
  margin-left: get-spacing('inline', 'xs');
}

/* Icon colors
----------------------------------------------- */

.a-icon--white {
  color: get-color('white');
}

.a-icon--brand {
  color: get-color('brand');
}
