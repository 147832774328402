/*------------------------------------*\
    #STACKED
\*------------------------------------*/

// scss-lint:disable ImportantRule, SelectorFormat

.u-stacked {
    margin-bottom: 0 !important;
}

.u-glued {
    margin-top: 0 !important;
}