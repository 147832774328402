.o-filter {
  margin-top: 0;
  position: relative;

  fieldset {
    min-width: 0;
    width: 100%;
  }
}

.o-filter__title {
  cursor: pointer;
  color: get-color('white');
  display: block;
  margin-top: 0;
  padding: baseline(.5) baseline(1.25) baseline(.5) baseline(.5);
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  font-family: $font-heading-light;

  .a-icon {
    position: absolute;
    right: baseline(.5);
    top: 50%;
    transform: translateY(-50%);
  }

  &.deactivated {
    .a-icon--caret-bottom {
      display: block;
    }

    .a-icon--caret-top {
      display: none;
    }
  }

  &.activated {
    .a-icon--caret-top {
      display: block;
    }

    .a-icon--caret-bottom {
      display: none;
    }
  }

  .o-filter__label,
  .o-filter__amount {
    display: inline-block;
    vertical-align: top;
    margin: 0;
  }

  .o-filter__amount {
    float:right;
  }

  .o-filter__label {
    width: 75%;
  }
}

.o-filter__options {
  background-color: get-color('white');
  box-shadow: 0 6px 15px 0 rgba(get-color('black'), .5);
  left: 0;
  margin-top: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  max-height: 0;
  transition: max-height .3s ease-out;
  overflow-y: auto;

  &.activated {
    max-height: baseline(11.5);
  }

  .a-input--control {
    padding-left: baseline(1);
  }
}