/*
  Desktop version
*/

.o-vacancy-list {
  z-index: 0;

  .o-vacancy-list__go-to-top {
    display: inline-block;
    float: right;
    margin-top: 0;
    text-decoration: none;
    white-space: nowrap;
  }

  .o-vacancy-list__paging {
    text-align: center;
  }

  .o-vacancy-list__header {
    display: inline-block;
  }

}

/*
  Mobile version
*/

@include respond-max(m) {
  .o-vacancy-list {
    position: relative;

    .a-h1 {
      order: 2;
      position: relative;
    }

    .o-vacancy-list__go-to-top {
      background-color: get-color('white');
      left: 0;
      order: 1;
      padding: get-spacing('inset-squish', 's');
      position: absolute;
      text-align: center;
      top: baseline(-3.5);
      right:0;
      width: 100%;
    }
  }
}


/*
  Active filters
*/

.o-active-filters {

}

.o-active-filters__summary {
  color: get-color('dark-grey');

  span {
    color: #7f7f7f;
    font-weight: bold;
  }

}

.o-active-filters__list {
  padding-left: 0;

  > li {
    display: inline-block;
    margin-right: baseline(.5);
  }

}

.o-active-filters__filter {
  background-color: get-color('dark-grey');
  color: get-color('white');
  display: inline-block;
  padding: baseline(.25) baseline(.5);

  .a-icon {
    margin-left: baseline(.5);
  }

}

.o-active-filters__reset {
  color: get-color('brand');
  font-size: 80%; // labels have 80%;
  padding-left: 0;
  padding-right: 0;
  text-transform: none;

  .a-icon {
    margin-left: baseline(.5);
  }

}