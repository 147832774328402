fieldset {
  border-width: 0;
  margin: 0;
  padding: 0;
  background: inherit;
}

legend {
  font-weight: bold;
  padding-top: baseline(1);
  margin-bottom: 0;
  display: block;
  color: get-color('brand');
  font-family: $font-heading-light;
}

label,
.a-label {
  display: block;
  margin-bottom: baseline(.5);
  font-size: 80%;
  color: get-color('dark-grey');
  font-family: $font-heading-light;
}

select,
input:not([type="radio"]):not([type="checkbox"]) {
  height: 3rem;
}

textarea,
select,
input {

  // remove default input styling on iOS devices
  &:not([type="radio"]):not([type="checkbox"]) {
    -webkit-appearance: none;
  }

  &:not([type="radio"]){
    background: transparent;
    border: 1px solid get-color('dark-grey');
  }

  border-radius: 0; // prevent rounded corners on iOS (except for type="search")
  box-shadow: none; // prevent default validation styling
  color: get-color('dark-grey');
  display: block;
  font-size: 80%;
  font-weight: normal;
  padding: baseline(.5);
  width: 100%;
  line-height: baseline(1);

  &:focus {
    outline: none;
  }

  &::-webkit-input-placeholder {
    color: get-color('dark-grey');
    font-family: $font-heading-light;
  }

  &[disabled] {
    opacity: .5;
  }

  &.invalid {
    border-color: get-color('error');
  }
}

.a-input {
  margin-top: 0;
}


/*------------------------------------*\
    $INPUT MODIFIER BORDER-BOTTOM
\*------------------------------------*/

.a-input--alt {

  input {
    border-color: get-color('white');
    border-width: 0 0 1px;
    font-size: 120%;

    &::-webkit-input-placeholder {
      color: rgba(get-color('white'), .6);
      font-family: $font-heading-light;
    }
  }
}

/*------------------------------------*\
    $INPUT MODIFIER SIZE
\*------------------------------------*/

.a-input--large {

  input {
    padding: baseline(1) baseline(.5);
  }
}

/*------------------------------------*\
    $INPUT MODIFIER diapositive
\*------------------------------------*/

.a-input--diapositive {

  input {
    border-color: get-color('white');
    color: get-color('white');
  }
}

/*------------------------------------*\
    $CUSTOM CHECKBOXES RADIO BUTTONS
\*------------------------------------*/

.a-input--control {
  display: block;
  position: relative;
  padding-left: baseline(.75);
  padding-bottom: 0;
  cursor: pointer;
  line-height: baseline(1);
  color: get-color('dark-grey');
  font-size: 80%;

  input,
  input[type="file"]{
    @include hide;
  }
}

.a-input__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  margin-top: 0;
  width: baseline(.75);
  height: baseline(.75);
  border: 1px solid get-color('dark-grey');


  .u-diapositive & {
    border-color: get-color('white');
  }

  .a-input--radio & {
    border-radius: 50%;
  }

  .input--control input:checked ~ & {
    border-color: get-color('dark-grey');

    .u-diapositive & {
      border-color: get-color('white');
    }
  }

  .a-input--control input:disabled ~ & {
    pointer-events: none;
    opacity: .25;
  }

  &::after {
    position: absolute;
    display: none;
    content: '';

    .a-input--control input:checked ~ & {
      display: block;
    }

    .a-input--checkbox & {
      top: 1px;
      left: 6px;
      width: 8px;
      height: 14px;
      transform: rotate(45deg);
      border: solid get-color('brand');
      border-width: 0 2px 2px 0;
      color: get-color('brand');

      .u-diapositive & {
        border-color: get-color('white');
      }
    }

    .a-input--checkbox input:disabled ~ & {
      border-color: get-color('dark-grey');
    }

    .a-input--radio & {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: get-color('brand');

      .u-diapositive & {
        background: get-color('white');
      }
    }

    .a-input--radio input:disabled ~ & {
      background: get-color('grey');
    }
  }
}

.a-radio--inline {
  position: relative;
  display: inline-block;
  padding-left: baseline(1.5);
  margin-bottom: 0;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;

  + .a-radio--inline {
      margin-top: 0;
      margin-left: baseline(1.5);
  }
}


/*------------------------------------*\
    $CUSTOM SELECT
\*------------------------------------*/

.a-select {
  position: relative;
  background: get-color('white');
  border: 1px solid get-color('grey');
  color: get-color('grey');
  display: inline-block;
  font-weight: normal;
  padding: baseline(.25) 0;
  width: 100%;


  &::before {
    content: 'a';
    opacity: 0;
  }

  select {
    appearance: none;
    border-width: 0;
    color: white;
    cursor: pointer;
    height: 100%;
    padding: 0;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;

    option {
      background-color: get-color('white');
      margin-top: 0;
      color: get-color('brand');
      font-style: normal;
      padding: baseline(.25) baseline(.5);
      border: none;

      &[value=""][disabled] {
        display: none;
      }
    }
  }

  &.is-initialized {

    &::before {
      display: none;
    }

    select {
      opacity: 0;
    }
  }

  .a-select__label {
    color: get-color('grey');
    font-weight: normal;
    padding-left: 0;
    outline: 0;
  }

  .a-select__arrow {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 50%;

    .u-diapositive & {
      color: get-color('white');
    }
  }

  &--selected {
    .a-select__label {
      color: get-color('white');
      font-style: normal;
    }
  }

  .u-diapositive & {
    border: 1px solid get-color('white');
    background-color: get-color('brand');

    .a-select__label,
    .a-select__arrow {
      color: rgba(get-color('white'), .6);
    }

    &.a-select--selected {
      .a-select__label {
        color: get-color('white');
      }
    }
  }
}