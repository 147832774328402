.o-vacancy-card {
  display: flex;
  min-height: baseline(11.75);

  a {
    box-shadow: 0 2px 6px 0 rgba(get-color('black'), .1);
    display: flex;
    flex-direction: column;
    padding: baseline(.5) baseline(1.25) baseline(1.25);
    text-decoration: none;
    transition: box-shadow .5s cubic-bezier(.25,.8,.25,1);
    outline: 0;
    width: 100%;

    /* line animate height 6px on hover*/
    &::before {
      background-color: get-color('light-brand');
      content:'';
      display: block;
      height: 3px;
      left: 0;
      position: absolute;
      top: 0;
      transition: height .2s ease, box-shadow .5s ease;
      width: 100%;
    }

    @include attention {
      box-shadow: 0 6px 15px 0 rgba(get-color('black'), .5);

      /* line animate height 6px on hover*/
      &::before {
        height: 6px;
      }

      .a-button {
        background-color: get-color('brand');
        color: get-color('white');
      }
    }

  }

  .o-vacancy-card__header {
    .a-h3 {
      margin-top: 0;
    }
  }

  .a-meta--language {
    display: flex;
    justify-content: flex-end;
    font-style: italic;
  }

  /* on hover width 100% */
  .o-vacancy-card__footer {
    margin-top: auto;
  }
}

.a-stack--brand {

  .a-h1 {
    color: get-color('white');
  }

  .a-header--line span:first-child {
    border-top: 3px solid get-color('white');
  }

  .o-vacancy-list-related__button .a-button {
    border-color: get-color('white');
    color: get-color('white');

    @include attention {
      background-color: get-color('white');
      color: get-color('brand');

      .a-icon {
        color: get-color('brand');
      }
    }
  }
}