.m-actions {
  @include list-nav;
  margin-top: - get-spacing('stack', 's');

  > * {
    margin-right: get-spacing('inline', 's');
    margin-top: get-spacing('stack', 's');
  }

  @include respond-max('s') {
    > * {
      display: block;
    }
  }

}