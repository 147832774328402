.o-gallery {
  overflow: hidden;
  width: 100%;
  height: auto;
  position: relative;
  padding-top: 49.55%;

  .o-gallery__image,
  img {
    margin: 0;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}