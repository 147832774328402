// Color definitions

$colors: (
  'brand':        #0099FF,
  'brand-dark':   #0088D6,
  'light-brand':  #99D4FB,
  'black':        #000000,
  'dark-grey':    #BABABA,
  'grey':         #E5E5E5,
  'light-grey':   #F1F1F1,
  'white':        #FFFFFF,
  'facebook':     #3B5998,
  'twitter':      #1DA1F2,
  'linkedin':     #0077B5,
  'xinq':         #026466,
  'square':       #91C73F,
  'error':        #FF1E00
);

// Helper

@function get-color($name) {
  @if map-has-key($colors, $name) {
    @return map-get($colors, $name);
  } @else {
    @error 'Color #{$name} not found in $colors';
  }
}

// Color usage. Do not use direct references to $colors in your code.