.a-pager {
  display: none;
}

@include respond-min('m') {
  .a-pager {
    background-color: get-color('brand');
    color: get-color('white');
    display: block;
    margin-top: 0;
    position: fixed;
    text-decoration: none;
    z-index: 1;
    @include clearfix;

    @include attention {
      .a-pager__label {
        max-width: 297px;
      }
    }
  }

  .a-pager__button {
    padding: baseline(1) baseline(.5);
    display: block;

    &::before {
      display: inline-block;
      font-family: 'icoMoon';
    }
  }

  .a-pager__button,
  .a-pager__label {
    margin-top: 0;
    font-size: 70%;
  }

  .a-pager__label {
    transition: all .3s ease;
    max-width: 0;
    overflow: hidden;
    height: 80px;

    .inner {
      padding: baseline(1);
      display: inline-block;
      white-space: nowrap;
    }
  }

  .a-pager--map {
    background-color: get-color('white');
    color: get-color('brand');
    font-size: 60%;
    height: baseline(3.75);
    padding: baseline(.5) baseline(.25);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    top: baseline(4.75);
    width: baseline(3.75);

    img,
    figcaption {
      margin: 0;
    }

    figcaption {
      bottom: 0;
      left: 0;
      padding: baseline(.5) baseline(.25);
      position: absolute;
      width: 100%;
    }
  }

  .a-pager--next,
  .a-pager--previous {
    top: 50%;
    transform: translateY(-50%);
  }

  .a-pager--next {
    right: 0;

    .a-pager__button,
    .a-pager__label {
      float: right;
    }

    .a-pager__button {
      &::before {
        content:'\e902';
      }
    }
  }

  .a-pager--previous {
    left: 0;

    .a-pager__button,
    .a-pager__label {
      float: left;
    }

    .a-pager__button {
      &::before {
        content:'\e90f';
      }
    }
  }
}



