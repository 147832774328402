/*------------------------------------*\
    #LIST-BARE
\*------------------------------------*/

/**
* The list-bare object simply removes any indents and bullet points from lists.
*/

.a-list-bare {
    @include list-bare;
}
