/*------------------------------------*\
    #MAP-GET-INDEX-OF-KEY
\*------------------------------------*/

/**
 * Map get index of key
 * @author Pim Derks
 * @access public
 * @param {Map} $map - Map
 * @param {String} $keys - Key
 * @return {*} - Index of key.
*/


@function map-get-index-of-key($map: (), $key: '') {
  $index: length($map);
  @for $i from 1 through length($map) {
    $set: nth($map, $i);
    $k: nth($set, 1);
    @if $k == $key {
      $index: $i;
    }
  }
  @return $index;
}
