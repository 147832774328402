.o-search-advanced {

  .o-filters {

    @include respond-min(m) {
      margin-top: baseline(1);
    }
  }

  .o-filter__title {
    background-color: get-color('brand');
    border: 1px solid get-color('white');
    font-size: 70%;
  }

  .o-search-advanced__submit {
    text-align: center;
    position: relative;
  }

  .o-search-advanced__amount {
    color: get-color('white');
    font-family: $font-heading-light;
    min-height: baseline(1);

    span {
      font-family: $font-heading-bold;
    }
  }
}