form,
.m-form {
  background-color: inherit;
}

.m-form__list {
  @include list-bare;
}

.m-form__item {
  position: relative;
}


/*
Form list inline
*/
.m-form--inline {

  .m-form__item {
    display: inline-block;
    vertical-align: middle;
    margin-top: 0;
    padding: baseline(.5) 0;
  }
}

/*
Form diapositive
*/
.m-form--diapositive {
  .a-label {
    color: get-color('white');
  }
}

.m-form__choices {

  label {
    margin-bottom: 0;
  }

  .m-form__item {
    padding: baseline(.25) 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.o-field {
  position: relative;
}




.o-field--validation {
  color: get-color('white');
  font-weight: normal;
  margin-top: baseline(.25);
  margin-bottom: 0;
  padding: baseline(.25) baseline(.5); // overwrite default label padding
  position: relative;
  text-align: left;
  width: 33.333%;

  // arrow
    &::before {
      border: baseline(.25) solid transparent;
      content: "";
      display: block;
      left: baseline(.5);
      position: absolute;
      top: -14px;
      width: 0;
    }
}

.o-field--invalid .o-field--validation {
  background: get-color('error');

  // arrow
  &::before {
    border-bottom-color: get-color('error');
  }
}

.o-field--required .o-field--validation {
  background: get-color('brand');

  // arrow
  &::before {
    border-bottom-color: get-color('brand');
  }
}

