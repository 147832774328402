@mixin icon() {
  font-family: 'icoMoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  speak: none;
  text-transform: none;
  line-height: 1;
  //line-height: baseline(1);
  vertical-align: -10%;
  font-size: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  margin-top: 0;
}