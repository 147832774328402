.o-job-alert {
  padding-top: baseline(6);

  .o-filter__title {
    background-color: get-color('white');
    border: 1px solid get-color('dark-grey');
    color: get-color('dark-grey');
    text-transform: none;
    font-size: 80%;
    line-height: baseline(1);

    .a-icon {
      color: get-color('dark-grey');
    }
  }

  .o-filters {
    margin-top: 0;
  }
}

.o-job-alert__submit {
  text-align: center;
}

.o-job-alert__contact {

  .a-label {
    display: block;

    @include respond-min(m) {
      padding-right: baseline(.75);
      display: inline-block;
    }
  }
}

.o-job-alert__message {
  display: none;
  text-align: center;
}