/* Generic dropdown styling, might be necessary to export this at a later stage to a separate file */

.m-dropdown {
  display: inline-block;
  position: relative;

  .m-dropdown__options {
    //background: red;
    @include list-bare;
    left: 0;
    margin-top: 0;
    min-width: 10em;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 15;

    > li {
      display: block;
      white-space: nowrap;
    }

    a,
    button {
      display: block;
      outline: none;

      &:focus {
        //background: pink;
      }

    }

    &.deactivated {
      display: none;
    }

    &.activated {
      display: block;
    }

    .is-selected {
      //background: green;
      //font-weight: bold;
    }

  }

  @include respond-min(l) {
    .m-dropdown__options {
      display: none;
    }
  }

}


// Default styling
.m-dropdown--default {

  .m-dropdown__options {
    background: get-color('brand');
    border: 1px solid get-color('white');

    a {
      display: block;
      padding: baseline(.5);
      text-decoration: none;

      @include attention {
        background-color: get-color('white');
        color: get-color('brand');
      }

    }

  }
}