/*------------------------------------*\
    #BODY
\*------------------------------------*/

/**
 * Simple page-level setup.
 *
 * 1. Hide horizontal overflow.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */

html {
  font-family: $font;
  font-weight: 200;
  overflow-x: hidden; /* [1] */
  overflow-y: scroll; /* [2] */
  min-height: 100%; /* [3] */
}

body {
  overflow-x: hidden;
}

body,
.a-page,
.a-pages {
  margin-top: 0;
}

html.has-overlay {
  overflow: hidden;

  .o-panel__overlay {
    display: block;
    opacity: .8;
    pointer-events: auto;
    transition: opacity 200ms ease-out 0ms;
  }
}
