$default-duration: 300ms;
$page-transition-duration: $default-duration;

.a-pages {
  position: relative;

  .a-pages__loading {
    background: get-color('white') url('../img/loading.gif') no-repeat center center;
    display: block;
    height: 100vh;
    left: 0;
    margin-top: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: all 200ms ease-out;
    top: 0;
    width: 100vw;
    z-index: -1;
  }

  &.is-loading .a-pages__loading {
    opacity: 1;
  }

  .a-page {
    z-index: 2;
  }

}

.a-page {
  opacity: 1;
  transition: all $page-transition-duration ease-out;

  &.a-page--come,
  &.a-page--go,
  &.a-page--hidden {
    opacity: 0;
  }

}

@include respond-min('m') {

  .a-page {
    opacity: 1;
    transition: all $page-transition-duration ease-out;

    // Stay on the same level

    &.a-page--same {

      &.a-page--come,
      &.a-page--go {
        opacity: 0;
        transform: scale(1);
      }

    }

    // Navigate to a lower level in the website

    &.a-page--down {

      &.a-page--come,
      &.a-page--go {
        opacity: 0;
        transform: scale(.98);
        transition-delay: 0ms;
      }

      &.a-page--go {
        transform: scale(1.02);
        transition-delay: $page-transition-duration/2;
      }

    }

    // Navigate to a higher level in the website

    &.a-page--up {

      &.a-page--come,
      &.a-page--go {
        opacity: 0;
        transform: scale(1.02);
      }

      &.a-page--go {
        transform: scale(.98);
        transition-delay: $page-transition-duration/2;
      }

    }

  }

}