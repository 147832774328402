.o-location-info-stack {
  padding: 0;

  .m-layout__item {
    margin-top: 0;
  }

  .o-location-info-stack__img {
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 270px;

    img,
    figure {
      opacity: 0;
      visibility: hidden;
      margin: 0;
    }
  }

  .o-location-info-stack__body {
    padding: baseline(2) baseline(1.25);
  }

  .o-location-info-stack__footer {
    padding: baseline(.25) baseline(1.25) baseline(2);
    margin-top: 0;
  }

  @include respond-min('m') {

    .o-location-info-stack__body {
      padding: baseline(2.5) baseline(2.5) baseline(1);
    }

    .o-location-info-stack__img {
      height: 385px;
    }

    .o-location-info-stack__footer {
      padding: baseline(.5) baseline(2.5) baseline(2);
      margin-top: 0;
    }
  }

  @include respond-min('l') {
    height: baseline(24.25);

    .o-location-info-stack__body {
      padding: baseline(5) baseline(2) baseline(1) baseline(2.5);
      height: baseline(20);
    }

    .content {
      height: 100%;
      overflow-y: auto;
      padding-right: baseline(.5);
    }

    .o-location-info-stack__img {
      height: baseline(24.25);
    }
  }
}