@mixin list-nav {
  @include list-bare;
  font-size: 0;

  > * {
    display: inline-block;
    font-size: 1rem;
    margin: 0;
  }

}
