/* Page Footer
---------------------------------------------------------*/

.o-page-footer {
  a {
    text-decoration: none;
  }

  .a-box {
    margin-bottom: baseline(2);

    @include respond-min(m) {
      margin-bottom: 0;
    }
  }

  .o-job-alert {
    padding-top: 0;
  }
}

.o-page-footer,
.o-page-footer__link,
.o-page-footer__disclaimer {
  margin: 0;
}

.o-page-footer__link,
.o-page-footer__disclaimer {
  display: block;

  @include respond-min(m) {
    display: flex;
  }
}
/* Page Footer job-alert
---------------------------------------------------------*/

.o-job-alert p {
  margin-top: baseline(.5);
}

.o-follow-us {
  text-align: center;

  @include respond-min(m) {
    text-align: left;
  }
}

/* Page Footer social list links
---------------------------------------------------------*/

.a-list--social {
  margin-top: baseline(.5);

  li {
    margin-left: 1px;
  }

  a {
    display: block;
    line-height: baseline(1.5);
    height: baseline(1.5);
    width: baseline(1.5);
    text-align: center;
    vertical-align: middle;
    color: get-color('white');

    @include attention {

    }
  }

  /*
    * 1. Colors for background social media links
  */

  .facebook {
    background-color: get-color('facebook');
  }

  .twitter {
    background-color: get-color('twitter');
  }

  .linkedin {
    background-color: get-color('linkedin');
  }

  .xing {
    background-color: get-color('xinq');
  }

  .glassdoor {
    background-color: get-color('square');
  }
}

/* Page Header Section Link to dllgroup
---------------------------------------------------------*/

.o-page-footer__link {
  text-align: center;
  font-size: 80%;

  @include respond-min(m) {
    justify-content: flex-end;
  }
}

/* Page Header Section disclaimer and Privacy
---------------------------------------------------------*/

.o-page-footer__disclaimer {
  background-color: get-color('black');
  display: flex;
  flex-direction: column;
  font-size: 80%;

  .disclaimer {
    display: flex;
    justify-content: center;

    a {
      font-size: 80%;
    }
  }

  li {
    margin-left: get-spacing('inline', 'l');
    align-items: center;

    &:first-child {
      margin-left: 0;
    }
  }

  .copyright {
    text-align: center;
  }

  @include respond-min(m) {
    flex-direction: row-reverse;
    justify-content: space-between;

    .copyright {
      margin-top: 0;
    }
  }
}