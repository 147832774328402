.o-header-stack {

}

.o-header-stack--image {
  position: relative;

  .a-h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    color: get-color('white');
    transform: translateY(-50%) translateX(-50%);
    font-family: $font-heading-light;
    z-index: 1;
  }
}