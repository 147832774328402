/*------------------------------------*\
    #QUOTES
\*------------------------------------*/

blockquote {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 40em;
    padding: 0;
    quotes: '\201C' '\201D' '\2018' '\2019';
    text-align: center;
    display: flex;
    -ms-flex: 0 1 auto;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    cite {
        display: block;
        font-style: normal;
        font-family: 'EffraLight', arial;
    }

    p {
        @include h3;
        font-weight: 200;
        color: get-color('brand')
    }

    p:first-of-type {
        &::before {
            content: open-quote;
        }
    }

    p:last-of-type {
        &::after {
            content: close-quote;
        }
    }

}
