.o-contact {

  @include respond-min(m){
    .m-layout__item {
      margin-top: 0;
    }
  }
}

.o-contact__address {

  dd,
  dt {
    margin-top: 0;

    a {
      text-decoration: none;
    }
  }

  .a-icon {
    margin-right: baseline(.25);
  }
}