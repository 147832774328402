.o-article {

  h2,
  h3,
  h4,
  h5,
  h6 {
    @include h4;
    line-height: baseline(1);
  }

  &--large {
    padding-top: baseline(6.5);
  }
}