.o-linkedin {
  padding: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: none;

  .a-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
  }

  .a-icon--linkedin {
    background-color: get-color('linkedin');
    padding: 3px 3px 6px 6px;

    &::before {
      color: white;
      text-align: center;
    }

  }

  .o-linkedin__plugin {
    display: none;
  }

  // Component only visible on bigger devices due to limited capabilities of LinkedIn plugin
  @include respond-min(m) {
    display: block;
  }

}

