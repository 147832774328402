.a-inner\-\- {
  padding: baseline(.5);
}

.a-inner\- {
  padding: baseline(.75);
}

.a-inner {
  padding: baseline(1);

  > *:last-child {
    margin-top: 0;
  }

}

.a-inner\+ {
  padding: baseline(1.25);
}

.a-inner\+\+ {
  padding: baseline(2);
}