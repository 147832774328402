/*------------------------------------*\
    #CLEARFIX
\*------------------------------------*/

/**
 * Micro clearfix, as per: css-101.org/articles/clearfix/latest-new-clearfix-so-far.php
 */

@mixin clearfix() {

  &::before,
  &::after {
    content: '';
    display: table;
  }

  &::after {
    clear: both;
  }

}