.a-animate {
  animation-duration: 4s;
  animation-iteration-count: 10;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.a-animate--bounce {
  animation-name: bounce;
}

@keyframes bounce {
  0% {transform: translateY(0);}
  35% {transform: translateY(0);}
  40% {transform: translateY(-20px);}
  45% {transform: translateY(0);}
  50% {transform: translateY(-10px);}
  54% {transform: translateY(0);}
  100% {transform: translateY(0);}
}